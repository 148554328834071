html,
body {
  margin: 0;
  padding: 0;
  color: #2a2a2a;
  font-family: sans-serif;
  background-color: #dedede;

  @media print {
    color: black;
    background-color: white;
  }
}

code,
pre {
  font-size: 0.85rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1.6em;
  position: relative;
}

h1 .anchor,
h2 .anchor,
h3 .anchor,
h4 .anchor,
h5 .anchor,
h6 .anchor {
  display: block;
  width: 1em;
  left: -1em;
  height: 100%;
  position: absolute;
}

h1:hover .anchor,
h2:hover .anchor,
h3:hover .anchor,
h4:hover .anchor,
h5:hover .anchor,
h6:hover .anchor {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cg fill='none' stroke='%23000' stroke-width='3'%3E%3Cpath d='M26.1 22a5.8 5.8 0 00-8.1 0l-9.6 9.5a5.8 5.8 0 008.2 8.2l5.8-5.9'/%3E%3Cpath d='M23.6 28a5.8 5.8 0 008.2 0l9.5-9.5a5.8 5.8 0 00-8.1-8.2l-5.8 5.9'/%3E%3C/g%3E%3C/svg%3E") no-repeat 100% center;
}

table {
  border: 1px solid #cfcfcf;
  border-collapse: collapse;
}

th {
  font-weight: 600;
}

td,
th {
  padding: 0.2em 0.4em;
  border: 1px solid #cfcfcf;
}

thead tr,
tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}

thead tr:hover,
tbody tr:hover {
  background-color: #cfcfcf;
}

.dragover {
  opacity: 0.5;
}

#header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: #fff;
  background-color: #24292e;

  @media print {
    display: none;
  }

  & .logo {
    flex-shrink: 0;
    width: 7.5rem;
    height: 2rem;
    margin: 0 0.6rem;
    font-size: 0;
    background: svg-load('icons/logo.svg', fill: #fff) no-repeat left center / contain;

    @media (max-width: 40em) {
      width: 3.8rem;
      margin: 0 0.2rem 0 0.5rem;
      background-size: 6.8rem 1.75rem;
    }
  }

  & .file-input {
    position: absolute !important;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    clip: rect(0, 0, 0, 0);
  }

  & .file-label {
    display: inline-block;
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.8rem;
    color: transparent;
    background: svg-load('icons/folder.svg', fill: #ddd) no-repeat center / contain;
    cursor: pointer;

    @media (max-width: 40em) {
      margin: 0.6rem;
    }
  }

  & .url-form {
    width: 100%;
  }

  & .url-input {
    box-sizing: border-box;
    width: 100%;
    margin: 0.6rem 0;
    padding: 0 0.5em;
    color: #fff;
    font-size: 0.9rem;
    line-height: 2;
    background-color: hsla(0, 0%, 100%, 0.15);
    border: 0;
    border-radius: 0.2em;
  }

  & .github-link {
    display: block;
    flex-shrink: 0;
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 1rem;
    font-size: 0;
    background: svg-load('icons/github.svg', fill: #eee) no-repeat center / contain;

    @media (max-width: 40em) {
      margin: 0 0.5rem;
    }
  }
}

.error {
  display: table;
  max-width: 28rem;
  height: 6rem;
  margin: 32vh auto 0 auto;
  padding-left: 8.5rem;
  background: svg-load('icons/alert.svg') no-repeat;
  background-position: left top;
  background-size: 6rem 6rem;

  @media (max-width: 40em) {
    height: unset;
    margin: 10vh auto 0 auto;
    padding: 8rem 1rem 0 1rem;
    text-align: center;
    background-position: top center;
  }

  & summary {
    display: block;
    font-size: 1.13em;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      /* Expand arrow after the summary text. */
      display: inline-block;
      width: 0.7em;
      height: 0.7em;
      margin-left: 0.5em;
      background: svg-load('icons/chevron-down.svg') no-repeat center / contain;
      content: '';
    }
  }

  & details[open] summary::after {
    /* Collapse arrow after the summary text. */
    background: svg-load('icons/chevron-up.svg') no-repeat center / contain;
  }

  & details :not(summary) {
    color: #666;
  }

  & :first-child {
    margin-top: 0;
  }
}

.fetching {
  width: 12rem;
  margin: 32vh auto 0 auto;
  font-size: 1.13rem;
  text-align: center;

  &::before {
    /* Spinner */
    display: block;
    width: 4rem;
    height: 4rem;
    margin: 0 auto 2rem auto;
    border-top: 0.3rem solid #f37726;
    border-right: 0.3rem solid transparent;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
    content: "";
  }
}

.nb-notebook {
  max-width: 45rem;
  margin: 1rem auto 2rem auto;
  padding: 3rem 6rem;
  background-color: white;
  box-shadow: 4px 4px 8px #cfcfcf, -4px -4px 8px #cfcfcf;

  @media screen and (max-width: 940px) {
    max-width: none;
    margin: 0;
    padding-right: 3rem;
    padding-left: 5rem;
    box-shadow: none;
  }

  @media screen and (max-width: 768px) {
    padding: 0.1rem 5% 2rem 5%;
  }

  @media print {
    max-width: none;
    margin: 0;
    padding: 0 0 0 5rem;
    box-shadow: none;
  }
}

.nb-output table {
  font-size: 0.9em;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@page {
  size: A4;
  margin: 30mm 15mm 15mm 7mm;
}
